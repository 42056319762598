import { useState } from "react";

import { Tabs, TabsContent } from "@/components/ui/tabs";

function HowSecondLabWorks() {
  const [currentTab, setCurrentTab] = useState("researcher");

  return (
    <>
      <div className="m-auto items-center gap-10 px-4 py-14 text-center md:pb-36 lg:max-w-screen-xl lg:px-4 xl:gap-20 xl:px-0">
        <h2 className="mb-8 text-interface-black md:text-5xl md:leading-[60px]">
          How Second Lab Works
        </h2>
        <div className="text-center">
          <Tabs className="" value={currentTab}>
            <div className="mx-4 mb-8 overflow-x-auto md:mx-52 md:mb-12">
              <div className="grid min-w-[800px] grid-cols-3">
                <h2
                  className={`${currentTab === "researcher" ? "p2-medium border-b-4 border-b-gold-400" : "p2 border-b border-gold-100 text-coffee-800"} h-9 cursor-pointer border-b md:h-12`}
                  onClick={() => setCurrentTab("researcher")}
                >
                  For Research Teams
                </h2>
                <h2
                  className={`${currentTab === "university" ? "p2-medium border-b-4 border-b-gold-400" : "p2 text-coffee-800"} h-9 cursor-pointer border-b border-gold-100 md:h-12`}
                  onClick={() => setCurrentTab("university")}
                >
                  For University Labs
                </h2>
                <h2
                  className={`${currentTab === "administrators" ? "p2-medium border-b-4 border-b-gold-400" : "p2 border-b border-gold-100 text-coffee-800"} h-9 cursor-pointer md:h-12`}
                  onClick={() => setCurrentTab("administrators")}
                >
                  For University Administrators
                </h2>
              </div>
            </div>

            <TabsContent value="researcher">
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-24">
                <img width={"100%"} src="/researcher.png" alt="researcher" />
                <div className="mt-6 md:mt-0">
                  <div className="h2 mb-4 text-left">
                    Easily set up university access and service agreements
                  </div>
                  <div className="p3 text-left">
                    Second Lab is the world&apos;s first secure marketplace for
                    hourly or recurring research reservations, allowing external
                    R&D teams to easily book university machines, facilities,
                    and processing services.  Signup is fast and easy. Our
                    comprehensive legal frameworks offer universal access to
                    dozens of top labs, while carefully safeguarding your unique
                    intellectual property and valuable trade secrets.
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="university">
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-24">
                <img width={"100%"} src="/uni-lab.png" alt="University Labs" />
                <div className="mt-6 md:mt-0">
                  <div className="h2 mb-4 text-left">
                    Born at UC Berkeley. Forged by leading scientists
                  </div>
                  <div className="p3 text-left">
                    Co-designed with UC Berkeley, Second Lab partners with
                    leading US research institutions to streamline private
                    access to excess laboratory capacity. Turn idle machines
                    into a stable new unrestricted revenue stream. Finance long
                    term equipment maintenance, personnel, and expansion plans.
                    Eliminate time consuming administrative headaches. And
                    quickly create a thriving ecosystem of science and
                    technology companies that rely on your campus to move
                    science forward. 
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="administrators">
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-24">
                <img
                  width={"100%"}
                  src="/uni-admin.png"
                  alt="University Administrators"
                />
                <div className="mt-6 md:mt-0">
                  <div className="h2 mb-4 text-left">
                    Train, certify, and communicate with confidence
                  </div>
                  <div className="p3 text-left">
                    Second Lab enhances operational compliance by streamlining
                    the training, certification, scheduling, safety, and
                    building access procedures for each individual facility. 
                    Create custom workflows and check-in procedures for each
                    individual machine, or search through our robust content
                    library for ideas. Share lab etiquette expectations,
                    preparation instructions, and cleanup checklists.  Manage
                    external access in real time: grant, limit, or revoke as
                    needed. And communicate easily and clearly across hundreds
                    of customer relationships.
                  </div>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default HowSecondLabWorks;
