import useSWR from "swr";

import { getApprovalListColumns } from "@/components/common/approval-list";
import { DataTable } from "@/components/table/data-table";
import { superFetchData } from "@/lib/utils";

export function UniversityApprovalList() {
  const { data } = useSWR("/orgs/approvals", superFetchData, {
    revalidateOnFocus: true,
    revalidateIfStale: true,
  });
  const columns = getApprovalListColumns({
    disabledInsuranceColumn: true,
    isUniversity: true,
  });
  return (
    <div className="px-5 py-10">
      <div className="flex items-center justify-between border-b border-b-coffee-600 pb-2">
        <h2 className="text-emerald-black">Approvals</h2>
      </div>
      <DataTable columns={columns} data={data?.lab_registrations} />
    </div>
  );
}
