import { NextPageContext } from "next";
import Head from "next/head";
import { useRouter } from "next/router";

import { Home } from "@/components/home/home";
import { HomeListings } from "@/components/home-listings/listings";
import MainLayout from "@/components/main-layout";
import { UniversityApprovalList } from "@/components/university/approval/list";
import useAuthStore from "@/stores/auth-store";

export function getServerSideProps(context: NextPageContext) {
  const { text, lab } = context.query;
  const labString = String(lab);
  return {
    props: {
      filters: {
        text: text || "",
        labs: lab
          ? { [labString]: { label: labString, value: labString } }
          : "",
        type: "all",
      },
    },
  };
}

export default function IndexPage(props: { filters: any }) {
  const { user } = useAuthStore();
  const router = useRouter();

  if (user?.user_type === "uni_office") {
    return (
      <>
        <Head>
          <title>Approvals | Second Lab</title>
          <meta name="description" content="Approvals page" />
        </Head>
        <UniversityApprovalList />
      </>
    );
  }

  const desc =
    "Access advanced scientific equipment and expertise at top researchers universities quickly and securely";

  if (user?.user_type === "university" && !user?.org?.app_switch_mode_enabled) {
    router.push("/hosting");
    return null;
  }

  return (
    <>
      <Head>
        <title>Second Lab</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content="Second Lab" />
        <meta property="og:description" content={desc} />
        <meta name="og:image" content="/home/hero.png" />
        <meta name="twitter:title" content="Second Lab" />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content="/home/hero.png" />
      </Head>
      {!!user ? <HomeListings filters={props.filters} /> : <Home />}
    </>
  );
}

IndexPage.getLayout = function getLayout(page: any) {
  return <MainLayout isPublic={true}>{page}</MainLayout>;
};
