import React from "react";

import { Dialog, DialogContent } from "@/components/ui/dialog";
function Hero() {
  const [showVideo, setShowVideo] = React.useState(false);
  return (
    <div className="mt-10 lg:mt-0 lg:w-[620px]">
      {showVideo ? (
        <>
          <Dialog
            defaultOpen
            onOpenChange={() => {
              setShowVideo(false);
            }}
          >
            <DialogContent className="h-[90%] max-w-[90%] overflow-visible rounded-lg p-4">
              <iframe
                className="h-full w-full"
                src="https://www.youtube.com/embed/QYnAwG5SQVw?rel=0"
                allowFullScreen
                title="Second Lab Demo: Streamline Lab Equipment and Service Bookings at Top Research Universities"
              />
              <div
                onClick={() => setShowVideo(false)}
                className="absolute right-[-20px] top-[-20px] cursor-pointer rounded-full bg-black p-3"
              >
                <img src="/icons/close-white.svg" alt="close" width={28} />
              </div>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <div className="relative w-full">
          <img src="/home/hero.jpg" alt="hero" className="w-full rounded-lg" />
          <div className="absolute left-0 top-0 h-full w-full rounded-lg bg-black/30">
            <div
              onClick={() => {
                setShowVideo(true);
              }}
              className="absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] cursor-pointer rounded-full bg-black/30 p-6"
            >
              <img src="/icons/play.svg" alt="play" width={42} className="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Hero;
